<template>
    <div>
      <event-header v-if="event!=null"
      >
        Résultats de l'épreuve : {{ event.name }}
      </event-header>
      <round-header v-else>
        Résultats de la catégorie
      </round-header>

        <div class="result-container">
            <detailed-results
                    :category = "category"
                    :event = "event"
                    class="results-container"
            />
        </div>
    </div>
</template>

<script>
import detailedResults from '@/components/DetailedResults'
import EventHeader from '../components/EventHeader.vue';
import RoundHeader from '../components/RoundHeader.vue';

export default {
    components:{ detailedResults, EventHeader, RoundHeader, },
    computed:{
        event() {
            return this.currentEvent;
            },
        category() {
            return this.currentCategory;
            },
    },
    mounted(){
      this.$hideLoader();
    }
}
</script>

<style scoped >
    div { font-size: 0.7rem;}
    @media screen and (max-width: 767px) and (orientation: portrait) {
        .results-container {
            background-color: aliceblue;
            margin: 20px;
         }
    }
</style>
